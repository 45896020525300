<template>
  <div class="contents-area checkout-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ translations("CheckoutPage") }}</h1>
          <Breadcrumbs />
        </div>
        <div class="checkout-area">
          <div class="checkout-form-area">
            <CheckoutFrom />
          </div>
          <div class="cart-list" v-if="listCart.length">
            <CartSide />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckoutFrom from "@/components/app/forms/CheckoutFrom";
import CartSide from "@/components/egrul/CartSide";

export default {
  name: "Checkout",
  components: {
    CheckoutFrom,
    CartSide,
  },
  metaInfo() {
    return {
      title: this.translations("CheckoutPage"),
    };
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["listCart", "translations"]),
  },
};
</script>

<template>
  <div
    :class="[
      'form-field select-form-field',
      { hasValue: selectedValue },
      { invalid: v && v.$error },
      { valid: !v ? false : !v.$error && v.$dirty },
    ]"
  >
    <multiselect
      v-model="selectedValue"
      :options="items"
      :allowEmpty="allowEmpty"
      :placeholder="''"
      :selectLabel="translations('PressEnter2Select')"
      :selectGroupLabel="translations('PressEnter2SelectGroup')"
      :selectedLabel="translations('Selected')"
      :deselectLabel="allowEmpty ? translations('PressEnter2Remove') : ''"
      :deselectGroupLabel="
        allowEmpty ? translations('PressEnter2RemoveGroup') : ''
      "
      label="text"
      track-by="value"
    ></multiselect>
    <label
      >{{ fieldLabel }}
      <span v-if="v && v.$params['required']" class="required">*</span>
    </label>
    <small :class="{ opened: errorMessage }" class="error-label">{{
      errorMessage
    }}</small>
  </div>
</template>

<script>
import { getValidationErrorMessage } from "@/config";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  name: "SelectField",
  components: {
    Multiselect,
  },
  props: {
    fieldLabel: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => null,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    v: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters(["translations"]),
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        console.log(value);
        //        this.v.$reset();
        if (this.v) {
          this.v.$touch();
        }

        this.$emit("input", value);
      },
    },
    errorMessage() {
      if (this.v && this.v.$error) {
        let msg = getValidationErrorMessage(this.v);
        return this.translations(msg.message, msg.params || []);
      }
      return "";
    },
  },
};
</script>

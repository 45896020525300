<template>
  <div class="cart-list-inner">
    <h4>{{ translations("YouAreBuying") }}</h4>
    <div class="cart-products">
      <div class="cart-product" v-for="item in listCart" :key="item.id">
        <div class="image">
          <img :src="'//' + serverLink + item.thumb" :alt="item.product_name" />
        </div>
        <div class="details">
          <div>
            <p class="company grey">
              {{
                item.company_name +
                " " +
                (item.company_inn && item.company_inn !== "-"
                  ? "- " + item.company_inn
                  : "") +
                " (" +
                item.company_country +
                ")"
              }}
            </p>
            <p class="name">{{ item.product_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters } from "vuex";

export default {
  name: "Checkout",
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
    };
  },
  computed: {
    ...mapGetters(["listCart", "translations"]),
  },
};
</script>

<template>
  <div class="user-yurlitsa-select">
    <PaginationTable
      :itemsInPage="itemsPerPage"
      :pushState="false"
      :getTotalCount="itemsCount"
      :getPageContent="fetchPage"
      :addItemsFull="false"
    >
      <template v-slot:document="{ curItem }">
        <div class="yourlitso-content" @click="doYurlitsoSelect(curItem)">
          <div class="text">
            <h5>{{ curItem.inn }}</h5>
            <h3>{{ curItem.name }}</h3>
            <p v-if="curItem.yur_address">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 16.1749L12.7125 12.4624C13.4467 11.7282 13.9466 10.7927 14.1492 9.77435C14.3517 8.75596 14.2477 7.70039 13.8503 6.74111C13.4529 5.78183 12.78 4.96192 11.9167 4.38507C11.0533 3.80821 10.0383 3.50032 9 3.50032C7.96167 3.50032 6.94666 3.80821 6.08332 4.38507C5.21997 4.96192 4.54706 5.78183 4.14969 6.74111C3.75231 7.70039 3.64831 8.75596 3.85084 9.77435C4.05337 10.7927 4.55333 11.7282 5.2875 12.4624L9 16.1749ZM9 18.2959L4.227 13.5229C3.28301 12.5789 2.64014 11.3762 2.3797 10.0668C2.11925 8.75746 2.25293 7.40026 2.76382 6.16687C3.27472 4.93347 4.13988 3.87927 5.24991 3.13757C6.35994 2.39588 7.66498 2 9 2C10.335 2 11.6401 2.39588 12.7501 3.13757C13.8601 3.87927 14.7253 4.93347 15.2362 6.16687C15.7471 7.40026 15.8808 8.75746 15.6203 10.0668C15.3599 11.3762 14.717 12.5789 13.773 13.5229L9 18.2959ZM9 10.2499C9.39783 10.2499 9.77936 10.0919 10.0607 9.8106C10.342 9.5293 10.5 9.14777 10.5 8.74994C10.5 8.35212 10.342 7.97059 10.0607 7.68928C9.77936 7.40798 9.39783 7.24994 9 7.24994C8.60218 7.24994 8.22065 7.40798 7.93934 7.68928C7.65804 7.97059 7.5 8.35212 7.5 8.74994C7.5 9.14777 7.65804 9.5293 7.93934 9.8106C8.22065 10.0919 8.60218 10.2499 9 10.2499ZM9 11.7499C8.20435 11.7499 7.44129 11.4339 6.87868 10.8713C6.31607 10.3087 6 9.54559 6 8.74994C6 7.95429 6.31607 7.19123 6.87868 6.62862C7.44129 6.06601 8.20435 5.74994 9 5.74994C9.79565 5.74994 10.5587 6.06601 11.1213 6.62862C11.6839 7.19123 12 7.95429 12 8.74994C12 9.54559 11.6839 10.3087 11.1213 10.8713C10.5587 11.4339 9.79565 11.7499 9 11.7499Z"
                  fill="#7D7E8B"
                /></svg
              >{{ curItem.yur_address }}
            </p>
          </div>
        </div>
      </template>
      <template v-slot:empty>
        <div class="empty-list">
          <svg
            width="124"
            height="109"
            viewBox="0 0 124 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.7383 18.7344V24.9453C74.7383 26.6842 73.3721 28.0508 71.6328 28.0508H68.5273V18.7344C68.5273 16.9951 67.1611 15.6289 65.4219 15.6289H40.5781C38.8389 15.6289 37.4727 16.9951 37.4727 18.7344V28.0508H34.3672C32.6279 28.0508 31.2617 26.6842 31.2617 24.9453V18.7344C31.2617 13.5791 35.4228 9.41797 40.5781 9.41797H65.4219C70.5772 9.41797 74.7383 13.5791 74.7383 18.7344Z"
              fill="#5A5A5A"
            />
            <path
              d="M65.4219 15.6289H53V9.41797H65.4219C70.5772 9.41797 74.7383 13.5791 74.7383 18.7344V24.9453C74.7383 26.6842 73.3721 28.0508 71.6328 28.0508H68.5273V18.7344C68.5273 16.9951 67.1611 15.6289 65.4219 15.6289Z"
              fill="#5A5A5A"
            />
            <path
              d="M106 24.9452V93.2656C106 98.4204 101.839 102.582 96.6836 102.582H9.31641C4.16112 102.582 0 98.4204 0 93.2656V24.9452C0 24.0757 0.372656 23.3304 0.931848 22.7712L16.7695 58.4222H89.2305L105.068 22.771C105.627 23.3304 106 24.0757 106 24.9452Z"
              fill="#5A5A5A"
            />
            <path
              d="M106 24.9452V93.2656C106 98.4204 101.839 102.582 96.6836 102.582H53V58.4222H89.2305L105.068 22.771C105.627 23.3304 106 24.0757 106 24.9452Z"
              fill="#5A5A5A"
            />
            <path
              d="M105.068 22.7713L95.4412 58.4223C94.3232 62.5213 90.659 65.3164 86.4353 65.3164H19.5645C15.3406 65.3164 11.6764 62.5215 10.5584 58.4223L0.931641 22.7713C1.49042 22.2125 2.23573 21.8398 3.10526 21.8398H102.894C103.764 21.8398 104.509 22.2125 105.068 22.7713Z"
              fill="#6E6E6E"
            />
            <path
              d="M105.068 22.7713L95.4414 58.4223C94.3234 62.5213 90.6592 65.3164 86.4356 65.3164H53V21.8398H102.895C103.764 21.8398 104.509 22.2125 105.068 22.7713Z"
              fill="#6E6E6E"
            />
            <path
              d="M65.4219 56H40.5781C38.8389 56 37.4727 57.3662 37.4727 59.1055V71.5273C37.4727 73.2662 38.8389 74.6328 40.5781 74.6328H65.4219C67.1611 74.6328 68.5273 73.2662 68.5273 71.5273V59.1055C68.5273 57.3662 67.1611 56 65.4219 56Z"
              fill="#FFD400"
            />
            <path
              d="M68.5273 59.1055V71.5273C68.5273 73.2662 67.1611 74.6328 65.4219 74.6328H53V56H65.4219C67.1611 56 68.5273 57.3662 68.5273 59.1055Z"
              fill="#FDBF00"
            />
            <path
              d="M102 44C114.15 44 124 34.1503 124 22C124 9.84974 114.15 0 102 0C89.8497 0 80 9.84974 80 22C80 34.1503 89.8497 44 102 44Z"
              fill="#F15249"
            />
            <path
              d="M92.7695 31.9243L104.637 43.7918C114.631 42.612 122.542 34.7697 123.792 24.776L111.855 12.8391L92.7695 31.9243Z"
              fill="#AD0E0E"
            />
            <path
              d="M112.41 26.8582C113.382 27.8298 113.382 29.4955 112.41 30.4671L110.467 32.4103C109.495 33.3819 107.83 33.3819 106.858 32.4103L91.59 17.1422C90.6184 16.1705 90.6184 14.5049 91.59 13.5333L93.6027 11.5207C94.5743 10.5491 96.2399 10.5491 97.2115 11.5207L112.41 26.8582Z"
              fill="white"
            />
            <path
              d="M104.36 18.7383L98.7383 24.3597L106.858 32.4102C107.83 33.3818 109.495 33.3818 110.467 32.4102L112.48 30.3976C113.451 29.426 113.451 27.7604 112.48 26.7888L104.36 18.7383Z"
              fill="#D6D6D6"
            />
            <path
              d="M106.858 11.59C107.83 10.6184 109.495 10.6184 110.467 11.59L112.48 13.6027C113.451 14.5743 113.451 16.2399 112.48 17.2115L97.1421 32.4102C96.1705 33.3818 94.5049 33.3818 93.5333 32.4102L91.59 30.467C90.6184 29.4954 90.6184 27.8298 91.59 26.8582L106.858 11.59Z"
              fill="white"
            />
          </svg>
          <p>{{ translations("UserYurlitsaEmpty") }}</p>
        </div>
      </template>
    </PaginationTable>
  </div>
</template>

<script>
import PaginationTable from "@/components/PaginationTable";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SelectYurLitso",
  components: {
    PaginationTable,
  },
  data: () => {
    return {
      selectedItem: null,
      itemsPerPage: 3,
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "listYurlitsaLoaded",
      "listYurlitsaCount",
      "listYurlitsa",
      "listYurlitsaPage",
      "listYurlitso",
    ]),
  },
  methods: {
    ...mapActions(["userYurlitsa"]),
    async setDefault(id) {
      console.log("меняем по умолчанию + сами юрлица: ", id);
      await this.userYurlitsoUse(id);
    },
    async itemsCount() {
      console.log("получаем кол-во юрлиц + сами юрлица");
      if (!this.listYurlitsaLoaded) {
        await this.userYurlitsa({
          page: +this.$route.query.page || 1,
          pagesize: this.itemsPerPage,
        });
      }
      return this.listYurlitsaCount;
    },
    doYurlitsoSelect(item) {
      this.$emit("yurlitso-selected", item);
    },
    async fetchPage(page) {
      console.log("page: ", +page);
      console.log("YurlitsaPage: ", this.listYurlitsaPage);
      if (+this.listYurlitsaPage !== +page) {
        console.log("получаем сами юрлица");
        await this.userYurlitsa({ page, pagesize: this.itemsPerPage });
      }
      return this.listYurlitsa;
    },
  },
  async mounted() {
    //this.curItemsCount = await this.itemsCount();
  },
};
</script>
<template>
  <form :action="listPaymentForm.action" method="get" ref="paymentForm"></form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SberPaymentForm",
  computed: {
    ...mapGetters(["translations", "listPaymentForm"]),
  },
  mounted() {
    setTimeout(() => {
      console.log("listPaymentForm.action", this.listPaymentForm.action);
      location = this.listPaymentForm.action;
      //this.$refs.paymentForm.submit();
    }, 300);
  },
};
</script>

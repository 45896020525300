<template>
  <div class="checkout-form-content">
    <p v-if="!listCart.length">
      {{ translations("CartIsEmpty") }}
      <router-link to="/">{{
        String(translations("Search")).toLowerCase()
      }}</router-link>
    </p>
    <FormWrapper
      v-if="listCart.length"
      :formTitle="''"
      :formClass="'checkout-form'"
      :v="$v.form"
      :successMessage="translations('YourOrderHasBeenSubmitted')"
      :formCallback="doCheckout"
      @form-success="goFurther"
    >
      <transition name="fade" appear>
        <div class="flash flash-success" v-if="isUserLogged && flashVisible">
          <div class="flash-title">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1663 8.66651H19.4997C19.9417 8.66651 20.3656 8.8421 20.6782 9.15466C20.9907 9.46722 21.1663 9.89114 21.1663 10.3332V12.0865C21.1666 12.3043 21.1241 12.52 21.0413 12.7215L18.4622 18.984C18.3993 19.1367 18.2924 19.2672 18.1552 19.3591C18.0179 19.4509 17.8565 19.4999 17.6913 19.4998H3.66634C3.44533 19.4998 3.23337 19.412 3.07709 19.2558C2.92081 19.0995 2.83301 18.8875 2.83301 18.6665V10.3332C2.83301 10.1122 2.92081 9.9002 3.07709 9.74392C3.23337 9.58764 3.44533 9.49984 3.66634 9.49984H6.56801C6.70142 9.49987 6.83289 9.46787 6.95137 9.40653C7.06985 9.34519 7.17186 9.2563 7.24884 9.14734L11.793 2.70817C11.8505 2.62677 11.9352 2.56864 12.0318 2.54433C12.1284 2.52002 12.2305 2.53114 12.3197 2.57567L13.8313 3.33151C14.2568 3.54414 14.5966 3.89593 14.7945 4.32843C14.9923 4.76094 15.0362 5.24811 14.9188 5.70901L14.1663 8.66651ZM7.83301 10.8232V17.8332H17.133L19.4997 12.0865V10.3332H14.1663C13.9125 10.3331 13.662 10.2751 13.434 10.1636C13.206 10.052 13.0065 9.88981 12.8507 9.68941C12.6949 9.48901 12.587 9.25567 12.5351 9.00719C12.4832 8.75872 12.4888 8.50167 12.5513 8.25567L13.3038 5.29901C13.3274 5.20678 13.3187 5.10927 13.2791 5.0227C13.2395 4.93612 13.1715 4.86571 13.0863 4.82317L12.5355 4.54817L8.61051 10.1082C8.40217 10.4032 8.13551 10.6448 7.83301 10.8232ZM6.16634 11.1665H4.49967V17.8332H6.16634V11.1665Z"
                fill="#00BA1E"
              />
            </svg>
            <div class="title">
              <span class="name">{{
                translations("DataFilledAutomatically")
              }}</span>
              <span class="link" @click="flashVisible = false">{{
                translations("Understood")
              }}</span>
            </div>
          </div>
        </div>
      </transition>
      <h3 :style="'display:' + (isUserLogged ? 'none' : 'block')">
        {{ translations("EnterPersonalDetails") }}
      </h3>
      <div
        class="sixty-percent-row"
        :style="'display:' + (isUserLogged ? 'none' : 'block')"
      >
        <TextField
          v-model="form.firstName"
          :v="$v.form.firstName"
          :fieldLabel="translations('EnterYourFullName')"
        />
      </div>
      <div
        class="sixty-percent-row"
        :style="'display:' + (isUserLogged ? 'none' : 'block')"
      >
        <div class="two-fields">
          <EmailField
            v-model="form.email"
            :v="$v.form.email"
            :fieldLabel="translations('EnterYourEmail')"
          />
          <PhoneField
            v-model="form.phone"
            :v="$v.form.phone"
            :fieldLabel="translations('EnterYourPhone')"
            :phoneMask="
              (ListLoggedUser && ListLoggedUser.phoneMask.mask) || phoneMask
            "
            :countryCode="
              (ListLoggedUser && ListLoggedUser.phoneMask.iso_code_2) || 'RU'
            "
            @code-change="codeChange($event)"
          />
        </div>
      </div>
      <hr :style="'display:' + (isUserLogged ? 'none' : 'block')" />
      <h3>{{ translations("ChoosePaymentMethod") }}</h3>
      <div class="payment-methods">
        <div class="payment-method">
          <div class="radio">
            <div class="radio-inner">
              <input
                :id="paymentIds.raschSch"
                type="radio"
                value="raschSch"
                v-model="form.paymentMethod"
              />
              <i></i>
            </div>
            <label :for="paymentIds.raschSch">
              <span class="name">{{ translations("ToBankAccount") }}</span>
              <span class="description">
                {{ translations("RaschSchetDesc") }}
              </span>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.001 12.5C20.6482 12.5 21.1729 11.9753 21.1729 11.3281C21.1729 10.6809 20.6482 10.1563 20.001 10.1563C19.3538 10.1563 18.8291 10.6809 18.8291 11.3281C18.8291 11.9753 19.3538 12.5 20.001 12.5Z"
                  fill="#707684"
                />
                <path
                  d="M33.8292 32.5391H6.17285C5.52563 32.5391 5.00098 33.0638 5.00098 33.711C5.00098 34.3582 5.52563 34.8829 6.17285 34.8829H33.8292C34.4764 34.8829 35.0011 34.3582 35.0011 33.711C35.0011 33.0638 34.4764 32.5391 33.8292 32.5391Z"
                  fill="#707684"
                />
                <path
                  d="M35.0011 12.4414C35.0011 11.9781 34.7281 11.5583 34.3047 11.3703L20.4472 5.21793C20.1437 5.08317 19.7976 5.08358 19.4945 5.2187L5.69561 11.3711C5.27315 11.5594 5.00098 11.9788 5.00098 12.4414V15.5469C5.00098 15.5568 5.00221 15.5663 5.00244 15.5762C5.00221 15.586 5.00098 15.5956 5.00098 15.6055C5.00098 16.2527 5.52563 16.7773 6.17285 16.7773C6.86092 16.7773 7.16895 17.3659 7.16895 17.9492V28.8779C7.16895 28.9025 7.17118 28.9265 7.17264 28.9507C7.17112 28.9749 7.16895 28.9989 7.16895 29.0235C7.16895 29.6707 7.6936 30.1953 8.34083 30.1953H31.7784C32.4256 30.1953 32.9503 29.6707 32.9503 29.0235C32.9503 28.3762 32.4256 27.8516 31.7784 27.8516H28.2628V17.9492C28.2628 17.303 28.7885 16.7773 29.4346 16.7773C30.0808 16.7773 30.6065 17.303 30.6065 17.9492V23.1358C30.6065 23.783 31.1312 24.3077 31.7784 24.3077C32.4256 24.3077 32.9503 23.783 32.9503 23.1358V17.9492C32.9503 17.6743 33.0137 16.7773 33.8292 16.7773C34.4764 16.7773 35.0011 16.2527 35.0011 15.6055C35.0011 15.5956 34.9998 15.586 34.9996 15.5762C34.9998 15.5663 35.0011 15.5568 35.0011 15.5469V12.4414ZM11.8565 27.8516H9.51271V17.9492C9.51271 17.303 10.0384 16.7773 10.6846 16.7773C11.3308 16.7773 11.8565 17.303 11.8565 17.9492V27.8516ZM16.544 27.8516H14.2002V17.9492C14.2002 17.303 14.7259 16.7773 15.3721 16.7773C16.0183 16.7773 16.544 17.303 16.544 17.9492V27.8516ZM21.2315 27.8516H18.8877V17.9492C18.8877 17.303 19.4134 16.7773 20.0596 16.7773C20.7058 16.7773 21.2315 17.303 21.2315 17.9492V27.8516ZM25.919 27.8516H23.5752V17.9492C23.5752 17.303 24.1009 16.7773 24.7471 16.7773C25.3933 16.7773 25.919 17.303 25.919 17.9492V27.8516ZM32.6573 14.375H7.34473V13.202L19.9728 7.57165L32.6573 13.2033V14.375Z"
                  fill="#707684"
                />
              </svg>
            </label>
          </div>
        </div>
        <div class="payment-method">
          <div class="radio">
            <div class="radio-inner">
              <input
                :id="paymentIds.bankCard"
                type="radio"
                value="bankCard"
                v-model="form.paymentMethod"
              />
              <i></i>
            </div>
            <label :for="paymentIds.bankCard">
              <span class="name">{{ translations("BankCard") }}</span>
              <span class="description">{{
                translations("BankCardDesc")
              }}</span>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5004 14.5143H9.90405L7.95712 7.07549C7.86471 6.7333 7.66849 6.43079 7.37987 6.28821C6.65958 5.9299 5.86587 5.64475 5 5.50093V5.21453H9.1825C9.75974 5.21453 10.1927 5.64475 10.2648 6.14439L11.275 11.5103L13.8701 5.21453H16.3943L12.5004 14.5143ZM17.8374 14.5143H15.3854L17.4045 5.21453H19.8565L17.8374 14.5143ZM23.0291 7.79081C23.1012 7.28993 23.5342 7.00353 24.0393 7.00353C24.833 6.93162 25.6976 7.07544 26.4191 7.43251L26.8521 5.43021C26.1305 5.14382 25.3368 5 24.6165 5C22.2366 5 20.5049 6.28816 20.5049 8.07597C20.5049 9.43604 21.7315 10.1502 22.5974 10.5804C23.5342 11.0094 23.8949 11.2958 23.8228 11.7247C23.8228 12.3682 23.1012 12.6546 22.3809 12.6546C21.5151 12.6546 20.6492 12.4401 19.8568 12.0818L19.4238 14.0853C20.2897 14.4424 21.2265 14.5862 22.0923 14.5862C24.7608 14.6569 26.4191 13.37 26.4191 11.4383C26.4191 9.00583 23.0291 8.86325 23.0291 7.79081ZM34.9998 14.5143L33.0528 5.21453H30.9616C30.5287 5.21453 30.0957 5.50093 29.9514 5.9299L26.3462 14.5143H28.8703L29.3742 13.1555H32.4756L32.7642 14.5143H34.9998ZM31.3236 7.71886L32.0439 11.2238H30.0248L31.3236 7.71886Z"
                  fill="#172B85"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M26.3682 35.9666C31.1353 35.9666 34.9998 31.9619 34.9998 27.0218C34.9998 22.0817 31.1353 18.0769 26.3682 18.0769C24.2314 18.0769 22.2761 18.8815 20.7687 20.2142C19.2614 18.8817 17.3063 18.0774 15.1698 18.0774C10.4026 18.0774 6.53809 22.0821 6.53809 27.0222C6.53809 31.9623 10.4026 35.967 15.1698 35.967C17.3065 35.967 19.2619 35.1625 20.7692 33.8298C22.2765 35.1622 24.2317 35.9666 26.3682 35.9666Z"
                  fill="#ED0006"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.7686 33.8297C22.6244 32.1891 23.8012 29.7479 23.8012 27.0218C23.8012 24.2958 22.6244 21.8546 20.7686 20.2139C22.2758 18.8814 24.2311 18.077 26.3676 18.077C31.1348 18.077 34.9993 22.0817 34.9993 27.0218C34.9993 31.9619 31.1348 35.9667 26.3676 35.9667C24.2311 35.9667 22.2758 35.1622 20.7686 33.8297Z"
                  fill="#F9A000"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.7689 33.8297C22.6248 32.189 23.8015 29.7478 23.8015 27.0218C23.8015 24.2957 22.6248 21.8545 20.7689 20.2139C18.9131 21.8545 17.7363 24.2957 17.7363 27.0218C17.7363 29.7478 18.9131 32.189 20.7689 33.8297Z"
                  fill="#FF5E00"
                />
              </svg>
            </label>
          </div>
        </div>
        <div class="payment-method" v-show="false">
          <div class="radio">
            <div class="radio-inner">
              <input
                :id="paymentIds.webMoney"
                type="radio"
                value="webMoney"
                v-model="form.paymentMethod"
              />
              <i></i>
            </div>
            <label :for="paymentIds.webMoney">
              <span class="name">{{ translations("ToWebmoney") }}</span>
              <span class="description">
                {{ translations("ToWebmoneyDesc") }}
              </span>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.4234 6.8663C22.5388 6.8663 24.5421 7.34653 26.3264 8.19911C26.6067 8.32871 26.8861 8.47129 27.1664 8.62872L25.9262 9.71081L24.0628 7.83258L20.9179 10.5671L19.0499 8.57969L13.1915 13.7275L16.9473 17.7625L15.4714 19.028L19.1706 23.0691L17.7034 24.3317L22.9895 30.0676L26.1274 27.2515L28.8403 30.2693C28.3006 30.6783 27.7129 31.0631 27.0653 31.4187C25.1035 32.5093 22.8362 33.1337 20.4234 33.1337C13.0031 33.1337 6.95117 27.2332 6.95117 20.0004C6.95117 12.766 13.0031 6.8663 20.4234 6.8663ZM17.9686 19.1701L21.1722 16.3577L24.0569 19.4815L20.8536 22.2929L17.9686 19.1701ZM23.4529 21.7548L20.249 24.5664L23.1343 27.6903L26.3379 24.8778L23.4529 21.7548ZM15.6761 13.8367L18.8798 11.0251L21.7637 14.1481L18.5601 16.9598L15.6761 13.8367ZM23.9617 10.2145L21.5589 12.323L23.7222 14.6661L26.1249 12.5567L23.9617 10.2145ZM25.8257 21.9829L28.2292 19.8736L30.3925 22.2164L27.9897 24.3252L25.8257 21.9829ZM30.3407 24.6046L27.9385 26.7142L30.1018 29.0556L32.5045 26.9468L30.3407 24.6046ZM28.9073 15.8707L30.5091 14.4648L31.9511 16.0263L30.3501 17.432L28.9073 15.8707ZM28.463 9.82519L26.8612 11.2309L28.3031 12.7924L29.9052 11.3867L28.463 9.82519ZM30.956 20.5102L32.5579 19.1042L34.0001 20.6655L32.3983 22.0714L30.956 20.5102ZM26.1717 14.9241L23.7693 17.0337L25.933 19.3752L28.335 17.2664L26.1717 14.9241Z"
                  fill="#0068A3"
                />
              </svg>
            </label>
          </div>
        </div>
        <div class="payment-method" v-show="false">
          <div class="radio">
            <div class="radio-inner">
              <input
                :id="paymentIds.youMoney"
                type="radio"
                value="youMoney"
                v-model="form.paymentMethod"
              />
              <i></i>
            </div>
            <label :for="paymentIds.youMoney">
              <span class="name">{{ translations("ToYooMoney") }}</span>
              <span class="description">
                {{ translations("ToYooMoneyDesc") }}
              </span>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.5193 9.17732C18.2363 9.17732 13.2257 14.2277 13.2257 20.5C13.2257 26.7723 18.3158 31.8227 24.5193 31.8227C30.7228 31.8227 35.8129 26.7723 35.8129 20.5C35.8924 14.2277 30.7228 9.17732 24.5193 9.17732ZM24.5193 24.6544C22.2129 24.6544 20.2246 22.7809 20.2246 20.5C20.2246 18.2192 22.1333 16.3457 24.5193 16.3457C26.9053 16.3457 28.814 18.2192 28.814 20.5C28.655 22.7809 26.9053 24.6544 24.5193 24.6544ZM13.1462 12.5171V29.0532H9.16959L4 12.5171H13.1462Z"
                  fill="#8B3FFD"
                />
              </svg>
            </label>
          </div>
        </div>
        <div class="payment-method" v-show="false">
          <div class="radio">
            <div class="radio-inner">
              <input
                :id="paymentIds.qiwi"
                type="radio"
                value="qiwi"
                v-model="form.paymentMethod"
              />
              <i></i>
            </div>
            <label :for="paymentIds.qiwi">
              <span class="name">{{ translations("ToQiwi") }}</span>
              <span class="description">{{ translations("ToQiwiDesc") }}</span>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.1987 25.2065C30.2946 25.8505 30.0921 26.117 29.8873 26.117C29.6655 26.117 29.3841 25.8569 29.0556 25.3408C28.7293 24.8332 28.612 24.2511 28.7719 23.9546C28.8786 23.7606 29.1025 23.6753 29.3841 23.7819C29.9278 23.9888 30.1411 24.8226 30.1987 25.2065ZM27.1853 26.6097C27.8379 27.162 28.0362 27.8189 27.6907 28.2816C27.4988 28.5354 27.1875 28.6761 26.8142 28.6761C26.4474 28.6761 26.0743 28.5397 25.8162 28.3136C25.2234 27.7954 25.0485 26.953 25.4366 26.4753C25.588 26.2877 25.8333 26.1853 26.1297 26.1853C26.488 26.1853 26.8633 26.3367 27.1853 26.6097ZM26.0934 31.939C28.9063 31.939 31.958 32.9029 35.336 35.897C35.6751 36.1977 36.125 35.8245 35.8308 35.4514C32.5104 31.2587 29.4394 30.4611 26.3771 29.7872C22.6323 28.9619 20.7044 26.8549 19.361 24.539C19.0943 24.0783 18.9749 24.1593 18.9494 24.7522C18.9195 25.4751 18.9707 26.4348 19.1327 27.3753C18.9813 27.3817 18.8299 27.3795 18.6764 27.3795C13.3172 27.3795 8.97316 23.0355 8.97316 17.6764C8.97316 12.3172 13.3172 7.97313 18.6764 7.97313C24.0355 7.97313 28.3796 12.3172 28.3796 17.6764C28.3796 18.0581 28.3603 18.4355 28.3177 18.8045C27.6097 18.6765 26.215 18.6616 25.2362 18.7469C24.8672 18.7789 24.9205 18.9495 25.1978 19.0007C28.4052 19.5807 30.6081 21.5683 31.1135 25.1744C31.1242 25.2576 31.2329 25.2832 31.2756 25.2108C32.5957 23.0078 33.3527 20.4316 33.3527 17.6764C33.357 9.57256 26.7844 2.99997 18.6785 2.99997C10.5726 2.99997 4 9.57256 4 17.6784C4 25.7844 10.5726 32.357 18.6785 32.357C20.8281 32.357 22.9628 31.939 26.0934 31.939Z"
                  fill="#FF8C00"
                />
              </svg>
            </label>
          </div>
        </div>
        <div class="payment-method" v-show="false">
          <div class="radio">
            <div class="radio-inner">
              <input
                :id="paymentIds.payPal"
                type="radio"
                value="payPal"
                v-model="form.paymentMethod"
              />
              <i></i>
            </div>
            <label :for="paymentIds.payPal">
              <span class="name">{{ translations("ToPayPal") }}</span>
              <span class="description">{{
                translations("ToPayPalDesc")
              }}</span>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.5843 35.1808L15.1389 31.6389L13.9036 31.61H8.00488L12.1043 5.47277C12.117 5.39363 12.1583 5.32016 12.2186 5.26789C12.2792 5.21562 12.3564 5.18695 12.4372 5.18695H22.3833C25.6855 5.18695 27.9642 5.87777 29.1537 7.24148C29.7115 7.88122 30.0668 8.54993 30.2387 9.28555C30.419 10.0576 30.422 10.9799 30.2462 12.105L30.2334 12.1868V12.9078L30.7912 13.2256C31.2607 13.4761 31.6341 13.7629 31.9204 14.0911C32.3974 14.6382 32.706 15.3335 32.8364 16.1576C32.9713 17.0052 32.9267 18.0141 32.706 19.1562C32.4515 20.4697 32.0401 21.6139 31.4844 22.5499C30.9735 23.4125 30.3225 24.1281 29.5494 24.6824C28.8113 25.2092 27.9345 25.6092 26.9431 25.8651C25.9822 26.1166 24.8868 26.2435 23.6854 26.2435H22.9114C22.3581 26.2435 21.8205 26.4439 21.3984 26.8032C20.9752 27.17 20.6954 27.6711 20.6095 28.2192L20.551 28.5382L19.5712 34.7812L19.5269 35.0103C19.515 35.0828 19.4949 35.119 19.4652 35.1435C19.4387 35.1659 19.4007 35.1808 19.3636 35.1808H14.5843Z"
                  fill="#28356A"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.3186 12.2698C31.2892 12.4607 31.2551 12.6557 31.2171 12.8561C29.9054 19.6281 25.4179 21.9675 19.6867 21.9675H16.7685C16.0676 21.9675 15.4768 22.4791 15.3678 23.1744L13.4506 35.4033C13.3796 35.86 13.7295 36.2712 14.1875 36.2712H19.3632C19.976 36.2712 20.4966 35.8235 20.5932 35.2157L20.644 34.9514L21.6185 28.7329L21.6811 28.3918C21.7765 27.782 22.2983 27.334 22.9111 27.334H23.6851C28.6996 27.334 32.6252 25.2869 33.7726 19.3626C34.2516 16.8878 34.0036 14.8213 32.7354 13.368C32.3516 12.9299 31.8755 12.5661 31.3186 12.2698Z"
                  fill="#298FC2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M29.9461 11.7199C29.7456 11.661 29.5389 11.6079 29.3268 11.5598C29.1136 11.5129 28.8953 11.4714 28.6704 11.4349C27.8836 11.3071 27.0214 11.2465 26.098 11.2465H18.3023C18.1101 11.2465 17.9277 11.2901 17.7647 11.3689C17.405 11.5428 17.138 11.885 17.0732 12.3041L15.4147 22.8667L15.3672 23.1746C15.4762 22.4793 16.067 21.9677 16.7679 21.9677H19.6861C25.4172 21.9677 29.9048 19.6271 31.2164 12.8563C31.2556 12.6559 31.2886 12.4609 31.318 12.27C30.9862 12.0929 30.6269 11.9415 30.2398 11.8125C30.1442 11.7805 30.0456 11.7497 29.9461 11.7199Z"
                  fill="#22284F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.0742 12.3042C17.1389 11.8852 17.406 11.5429 17.7656 11.3703C17.9299 11.2911 18.1111 11.2475 18.3032 11.2475H26.099C27.0224 11.2475 27.8846 11.3084 28.6714 11.4363C28.8962 11.4724 29.1145 11.5142 29.3278 11.5611C29.5399 11.6089 29.7466 11.6624 29.9471 11.7209C30.0466 11.7508 30.1452 11.7818 30.2417 11.8126C30.6287 11.9416 30.9884 12.0942 31.3202 12.2702C31.7104 9.76763 31.3169 8.06374 29.9714 6.52083C28.4878 4.82202 25.8106 4.09506 22.3845 4.09506H12.4381C11.7383 4.09506 11.1413 4.60668 11.0332 5.30317L6.89049 31.7091C6.80881 32.2315 7.20948 32.7028 7.73341 32.7028H13.8739L17.0742 12.3042Z"
                  fill="#28356A"
                />
              </svg>
            </label>
          </div>
        </div>
      </div>
      <div class="yur-block" v-if="form.paymentMethod === 'raschSch'">
        <h3>{{ translations("AddUserYurlitso") }}</h3>
        <div :class="isUserLogged ? 'three-fields' : 'two-fields'">
          <TextField
            v-model="form.yurName"
            :v="$v.form.yurName"
            :fieldLabel="translations('YurlitsoName')"
          />
          <TextField
            v-model="form.yurInn"
            :v="$v.form.yurInn"
            :fieldLabel="translations('Inn')"
          />
          <div v-if="isUserLogged" class="form-field text-right">
            <button
              type="button"
              class="btn btn-blue btn-big"
              @click="showYurSelect = true"
            >
              <span style="white-space: nowrap">{{
                translations("ChooseFromYourYurLitsa")
              }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="delivery-block" v-show="isDeliveryRequired">
        <h3>{{ translations("UserDeliveryAddress") }}</h3>
        <div class="two-fields">
          <SelectField
            v-model="form.country"
            :v="$v.form.country"
            :allowEmpty="false"
            style="z-index: 100"
            :items="allCountriesSelect"
            :fieldLabel="translations('UserDeliveryCountry')"
          />
          <TextField
            v-model="form.region"
            :v="$v.form.region"
            :fieldLabel="translations('UserDeliveryRegion')"
          />
        </div>
        <div class="three-fields">
          <TextField
            v-model="form.city"
            :v="$v.form.city"
            :fieldLabel="translations('UserDeliveryCity')"
          />
          <TextField
            :class="'double-field'"
            v-model="form.address"
            :v="$v.form.address"
            :fieldLabel="translations('YourAddress')"
          />
        </div>
        <div class="three-fields">
          <TextField
            v-model="form.contactindex"
            :v="$v.form.contactindex"
            :fieldLabel="translations('EnterContactIndex')"
          />
          <TextField
            v-model="form.contactname"
            :v="$v.form.contactname"
            :fieldLabel="translations('EnterContactName')"
          />
          <PhoneField
            v-model="form.contactphone"
            :v="$v.form.contactphone"
            :fieldLabel="translations('EnterContactPhone')"
            :phoneMask="
              (ListLoggedUser && ListLoggedUser.contact_phoneMask.mask) ||
              phoneMask
            "
            :countryCode="
              (ListLoggedUser && ListLoggedUser.contact_phoneMask.iso_code_2) ||
              'RU'
            "
            @code-change="contactCodeChange($event)"
          />
        </div>
      </div>
      <TextField
        v-model="form.comment"
        :v="$v.form.comment"
        :fieldLabel="translations('OrderComment')"
      />
      <div class="flash flash-info" v-if="!isUserLogged && isUserExists">
        <div class="flash-title">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
              fill="#E8C300"
            />
          </svg>
          <div class="title">
            <span class="link" @click="setReturning">{{
              translations("Authorize")
            }}</span>
          </div>
        </div>
      </div>
      <template v-slot:submit="{ status }">
        <div class="amount-n-confirm">
          <div class="amount">
            {{ translations("PaymentSum") }}<br />
            <div class="amount-sum">
              <span class="euro"
                >€{{ numberFormat(listCartTotal, 0, "", "&nbsp;") }}</span
              >
              <span class="ruble grey"
                >{{
                  numberFormat(
                    Math.ceil(
                      listCartTotal * parseFloat(listSettings.rubleCourse)
                    ),
                    0,
                    "",
                    "&nbsp;"
                  )
                }}&nbsp;₽</span
              >
            </div>
          </div>
          <div class="duration" v-if="listCartDuration.text">
            {{ translations("Duration") }}<br />
            <span class="grey">
              {{ listCartDuration.text }}
              {{
                workingDays[
                  pluralType(listCartDuration.to, $cookies.get("user_language"))
                ]
              }}
            </span>
          </div>
          <button
            class="btn btn-blue btn-big"
            :class="{ pending: status === 'PENDING' }"
            type="submit"
            :disabled="status === 'PENDING'"
          >
            <Loader v-if="status === 'PENDING'" :color="'white'" />
            <span>{{ translations("ProceedToPayment") }}</span>
          </button>
        </div>
      </template>
      <template v-slot:privacy>
        <p
          class="grey privacy"
          v-html="
            translations('AgreeToPrivacyAndOferta', [pricvacyText, ofertaText])
          "
        ></p>
      </template>
    </FormWrapper>
    <Popuper
      v-if="showYurSelect"
      :width="'850px'"
      @close="showYurSelect = false"
    >
      <template v-slot:header>
        <h3>{{ translations("ChooseYurLitso") }}</h3>
      </template>
      <template v-slot:body>
        <SelectYurLitso @yurlitso-selected="fillYurlitso" />
      </template>
    </Popuper>
    <div style="display: none" v-if="isPaymentFormLoaded">
      <SberPaymentForm
        v-if="
          form.paymentMethod !== 'payPal' && form.paymentMethod !== 'raschSch'
        "
      />
    </div>
  </div>
</template>

<script>
import {
  VUE_APP_BACK_URL,
  defPhoneMask,
  numberFormat,
  pluralType,
} from "@/config";
import { mapGetters, mapActions } from "vuex";
import { helpers } from "vuelidate/lib/validators";
import Loader from "@/components/app/Loader";
import Popuper from "@/components/app/Popuper";
import FormWrapper from "@/components/app/forms/FormWrapper";
import PhoneField from "@/components/app/forms/FieldComponents/PhoneField";
import EmailField from "@/components/app/forms/FieldComponents/EmailField";
import TextField from "@/components/app/forms/FieldComponents/TextField";
import SelectField from "@/components/app/forms/FieldComponents/SelectField";
import SelectYurLitso from "@/components/egrul/account/SelectYurLitso";
import SberPaymentForm from "@/components/app/forms/payment/SberPaymentForm";
import {
  email,
  required,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

const phoneMask = defPhoneMask;
const fullphone = (field) => (value, vm) => {
  if (field === "phone") {
    return value.length === vm.phoneMask.mask.length || !helpers.req(value);
  } else if (field === "contactphone") {
    return (
      value.length === vm.contactphoneMask.mask.length || !helpers.req(value)
    );
  }
};

export default {
  name: "Checkout",
  components: {
    FormWrapper,
    EmailField,
    PhoneField,
    TextField,
    SelectField,
    Loader,
    Popuper,
    SelectYurLitso,
    SberPaymentForm,
  },
  metaInfo() {
    return {
      title: this.translations("CheckoutPage"),
    };
  },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      ofertaText: "",
      pricvacyText: "",
      paymentIds: {},
      pluralType,
      workingDays: [],
      flashVisible: false,
      showYurSelect: false,
      form: {
        firstName: "",
        email: "",
        phone: "",
        phoneMask: { code: "RU", mask: phoneMask },
        paymentMethod: "",
        yurName: "",
        yurInn: "",
        country: { value: "", text: "" },
        region: "",
        city: "",
        address: "",
        contactname: "",
        contactphone: "",
        contactphoneMask: { code: "RU", mask: phoneMask },
        contactindex: "",
        comment: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "listCart",
      "isCartLoaded",
      "listCartTotal",
      "listCartDuration",
      "listSettings",
      "isUserLogged",
      "ListLoggedUser",
      "isDeliveryRequired",
      "isOrderLoaded",
      "listOrder",
      "isPaymentFormLoaded",
      "listPaymentForm",
      "isCountriesLoaded",
      "isCountriesLoading",
      "allCountriesSelect",
      "defCountrySelect",
      "isUserExists",
    ]),
    getPhoneMask() {
      return defPhoneMask;
    },
  },
  validations() {
    let form_validation = {
      form: {
        firstName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
        },
        email: { email, required },
        phone: { fullphone: fullphone("phone") },
        yurName: {},
        yurInn: {},
        country: {},
        region: { maxLength: maxLength(100) },
        city: { maxLength: maxLength(100) },
        address: { maxLength: maxLength(1000) },
        contactname: { maxLength: maxLength(200) },
        contactindex: { maxLength: maxLength(50) },
        contactphone: { fullphone: fullphone("contactphone") },
        comment: { maxLength: maxLength(1000) },
      },
    };

    if (this.form.paymentMethod === "raschSch") {
      form_validation.form.yurName = {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      };
      form_validation.form.yurInn = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(12),
      };
    }
    if (this.isDeliveryRequired) {
      form_validation.form.country.required = required;
      form_validation.form.city.required = required;
      form_validation.form.address.required = required;
      form_validation.form.contactname.required = required;
      form_validation.form.contactphone.required = required;
    }

    return form_validation;
  },
  watch: {
    "form.firstName"(v) {
      //      this.$nextTick(() => {
      this.form.firstName = String(v).replace(/\d/g, "");
      //      });
    },
    "form.yurInn"(v) {
      //      this.$nextTick(() => {
      this.form.yurInn = String(v).replace(/\D/g, "").trim().substring(0, 12);
      //      });
    },
  },
  methods: {
    ...mapActions([
      "saveOrder",
      "clearCart",
      "phoneCodeChange",
      "fetchCountries",
    ]),
    numberFormat,
    setReturning() {
      localStorage.setItem("checkout_returning", "1");
      this.$router.push("/login");
    },
    fillYurlitso(item) {
      console.log(item);
      this.form.yurName = item.name;
      this.form.yurInn = item.inn;
      this.showYurSelect = false;
    },
    async doCheckout(recaptchaToken) {
      console.log("начало сохранения заказа");
      let cartIds = localStorage.getItem("cart_ids");
      cartIds = JSON.parse(cartIds);
      let customerId = 0;
      if (this.isUserLogged) {
        customerId = this.ListLoggedUser.id;
      }
      if (!this.isDeliveryRequired) {
        this.form.address = "";
        this.form.contactname = "";
        this.form.contactphone = "";
        this.form.contactindex = "";
      }
      await this.saveOrder({
        lang: this.$cookies.get("user_language"),
        customerId,
        cartIds,
        checkoutData: this.form,
        token: recaptchaToken,
        clientID: this.ListLoggedUser.clientID,
      });
    },
    goFurther() {
      // дальнейшие действия после успешной отправки формы
      let curPM = localStorage.getItem("paymentMethod");
      if (curPM !== this.form.paymentMethod) {
        localStorage.setItem("paymentMethod", this.form.paymentMethod);
        curPM = this.form.paymentMethod;
      }

      // отправка данных в эл коммерцию
      const actionField = {
        id: this.listOrder.order_id,
      };

      const orderProducts = [];
      this.listOrder.products.forEach((product) => {
        orderProducts.push({
          id: product.id,
          name: product.name,
          price: parseFloat(parseFloat(product.price).toFixed(2)),
          brand: "",
          category: product.company_country,
          variant: "Доставка " + (product.delivery ? "" : "не ") + "нужна",
          quantity: 1,
        });
      });

      if (curPM !== "raschSch") {
        if (this.ListLoggedUser.boughts === 0) {
          // новый пользователь
          actionField.goal_id = 225367643;
        } else {
          // старый пользователь
          actionField.goal_id = 225576930;
        }
      }

      window.dataLayer.push({
        ecommerce: {
          currencyCode: "EUR",
          purchase: {
            actionField,
            products: orderProducts,
          },
        },
      });

      setTimeout(() => {
        if (curPM === "raschSch") {
          this.clearCart();
          this.$router.push("/order-success");
        }
      }, 1000);
    },
    async codeChange(value) {
      await this.phoneCodeChange({ phoneField: "phone", code: value });
      this.form.phoneMask.code = value;
      this.form.phoneMask.mask = this.ListLoggedUser.phoneMask.mask;
    },
    async contactCodeChange(value) {
      await this.phoneCodeChange({ phoneField: "contact-phone", code: value });
      this.form.contactphoneMask.code = value;
      this.form.contactphoneMask.mask =
        this.ListLoggedUser.contact_phoneMask.mask;
    },
  },
  async mounted() {
    this.paymentIds = {
      raschSch: "rs-" + this._uid,
      bankCard: "bc-" + this._uid,
      webMoney: "wm-" + this._uid,
      youMoney: "ym-" + this._uid,
      qiwi: "qw-" + this._uid,
      payPal: "pp-" + this._uid,
    };
    if (this.isUserLogged) {
      this.form.firstName = this.ListLoggedUser.first_name;
      this.form.phone = this.ListLoggedUser.phone;
      this.form.phoneMask = {
        code: this.ListLoggedUser.phoneMask.iso_code_2,
        mask: this.ListLoggedUser.phoneMask.mask,
      };
      this.form.email = this.ListLoggedUser.email;
      this.form.yurName = this.ListLoggedUser.def_your_name;
      this.form.yurInn = this.ListLoggedUser.def_your_inn;
      if (this.isDeliveryRequired) {
        this.form.region = this.ListLoggedUser.region;
        this.form.city = this.ListLoggedUser.city;
        this.form.address = this.ListLoggedUser.address;
        this.form.contactname = this.ListLoggedUser.contact_name;
        this.form.contactphone = this.ListLoggedUser.contact_phone;
        this.form.contactindex = this.ListLoggedUser.contact_index;
      } else {
        this.form.address = "";
        this.form.contactname = "";
        this.form.contactphone = "";
        this.form.contactindex = "";
      }
      this.form.contactphoneMask = {
        code: this.ListLoggedUser.contact_phoneMask.iso_code_2,
        mask: this.ListLoggedUser.contact_phoneMask.mask,
      };
    }
    const curPM = localStorage.getItem("paymentMethod");
    if (curPM) {
      this.form.paymentMethod = curPM;
    } else {
      this.form.paymentMethod = "bankCard";
    }

    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      await this.fetchCountries({
        lang: this.$cookies.get("user_language"),
      });
    }
    if (!this.isUserLogged || !this.ListLoggedUser.country_id) {
      this.form.country = this.defCountrySelect;
    } else {
      this.form.country = this.allCountriesSelect.find(
        (country) => country.value === this.ListLoggedUser.country_id
      );
    }

    //this.flashVisible = true;

    this.workingDays = this.translations("PluralWorkingDays");
    this.workingDays = JSON.parse(this.workingDays.trim());

    this.ofertaText =
      '<a href="//' +
      this.serverLink +
      this.listSettings.dogovor_oferta +
      '" target="_blank">' +
      this.translations("OfertaAgreementRod") +
      "</a>";
    this.pricvacyText =
      '<a href="//' +
      this.serverLink +
      '/page/privacy-policy" target="_blank">' +
      this.translations("PrivacyAgreementRod") +
      "</a>";
  },
};
</script>
